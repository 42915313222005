import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-architecture',
  templateUrl: './site-architecture.component.html',
  styleUrls: ['./site-architecture.component.scss']
})
export class SiteArchitectureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
