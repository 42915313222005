import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BioComponent } from './home/bio/bio.component';
import { ServicesComponent } from './home/services/services.component';
import { PortfolioComponent } from './home/portfolio/portfolio.component';
import { PortfolioItemComponent } from './home/portfolio/portfolio-item/portfolio-item.component';
import { SiteArchitectureComponent } from './home/site-architecture/site-architecture.component';
import { HobbiesComponent } from './home/hobbies/hobbies.component';
import { ContactComponent } from './home/contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BioComponent,
    ServicesComponent,
    PortfolioComponent,
    PortfolioItemComponent,
    SiteArchitectureComponent,
    HobbiesComponent,
    ContactComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
