import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, throttleTime } from "rxjs/operators";

@Component({
  selector: 'app-hobbies',
  templateUrl: './hobbies.component.html',
  styleUrls: ['./hobbies.component.scss']
})
export class HobbiesComponent implements OnInit {

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  canvasContainerElement : HTMLCanvasElement | null = null;

  ngOnInit(): void {
    fromEvent(window, "resize")
      .pipe(throttleTime(500), debounceTime(500))
      .subscribe(() => this.draw());
  }

  ngAfterViewInit() {
    this.canvasContainerElement = this.elementRef.nativeElement.querySelector('#road-canvas');
    this.draw();
  }

  draw(){
    const canvas : HTMLCanvasElement = document.getElementById("road-canvas") as HTMLCanvasElement;
    canvas.width  = window.innerWidth;
    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      ctx?.beginPath();
      ctx?.moveTo(0,0);
      ctx?.lineTo(canvas.width,50);
      ctx?.moveTo(0,100);
      ctx?.lineTo(canvas.width,150);
      if (ctx){
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = 8;
      }
      ctx?.stroke();
      ctx?.moveTo(0,50);
      ctx?.setLineDash([20, 7]);
      ctx?.lineTo(canvas.width, 100);
      if (ctx){
        ctx.strokeStyle = "#ffffff";
        ctx.lineWidth = 4;
      }
      ctx?.stroke();
    }
  }

}
