<div class="services-container">
    <h1>What I like to work on...</h1>
    <div class="service-widgets">
        <div class="service-widget">
            <h1>Front-End Engineering</h1>
            <ul class="service-widget-list">
                <li>Angular</li>
                <li>React</li>
                <li>Typescript</li>
                <li>Javascript</li>
                <li>Flutter (Mobile)</li>
                <li>Xamarin (Cross-Platform)</li>
                <li>Wordpress (X Theme)</li>
            </ul>
        </div>
        <div class="service-widget">
            <h1>Back-End Engineering</h1>
            <ul class="service-widget-list">
                <li>Java</li>
                <li>C#</li>
                <li>Golang</li>
                <li>Some experience with C++ and PHP as well.</li>
            </ul>
        </div>
        <div class="service-widget">
            <h1>API Development</h1>
            <ul class="service-widget-list">
                <li>Springboot</li>
                <li>.NET</li>
                <li>Golang</li>
            </ul>
        </div>
        <div class="service-widget">
            <h1>DevOps Engineering</h1>
            <ul class="service-widget-list">
                <li>Azure DevOps</li>
                <li>Static Code Analysis</li>
                <li>PR Checks</li>
                <li>Visual Studio App Center</li>
                <li>UI/UX Testing with Ranorex</li>
            </ul>
        </div>
        <div class="service-widget">
            <h1>Cloud Engineering</h1>
            <ul class="service-widget-list">
                <li>Azure</li>
                <li>AWS</li>
                <li>GCP / Firebase</li>
            </ul>
        </div>
    </div>
</div>
