import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, throttleTime } from "rxjs/operators";

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.scss']
})
export class BioComponent implements OnInit {

  constructor(private renderer: Renderer2, private elementRef: ElementRef) { }

  milliSecondsSinceStart : number = Date.parse("01 May 2015 00:00:00 EST")
  milliSecondsToday : number = Date.now()
  numberOfMonths : number = 0;
  numberOfYears : number = 0;

  bioContainerElement : HTMLElement | null = null;

  ngOnInit(): void {
    this.initLengthOfExperience();
    fromEvent(window, "resize")
      .pipe(throttleTime(500), debounceTime(500))
      .subscribe(() => this.setHeight());
  }

  ngAfterViewInit() {
    this.bioContainerElement = this.elementRef.nativeElement.querySelector('.image-container');
    this.setHeight();
  }

  setHeight(){
    let height = window.innerHeight;
    this.renderer.setStyle(this.bioContainerElement, "min-height", `${height}px`);
    this.renderer.setStyle(this.bioContainerElement, "height", `${height}px`);
  }

  initLengthOfExperience = () => {
    let dateStart = new Date(this.milliSecondsSinceStart)
    let dateNow = new Date(this.milliSecondsToday)
    let monthDiff = (dateNow.getFullYear() * 12 + dateNow.getMonth()) -(dateStart.getFullYear() * 12 + dateStart.getMonth());
    this.numberOfYears = Math.floor(monthDiff / 12);
    this.numberOfMonths = monthDiff % 12;
  }
  

}
