<div class="home-container">
    <div class="home-container-row">
        <app-bio></app-bio>
    </div>
    <div class="home-container-row">
        <app-services></app-services>
    </div>
    <div class="home-container-row">
        <app-portfolio></app-portfolio>
    </div>
    <div class="home-container-row">
        <app-hobbies></app-hobbies>
    </div>
    <div class="home-container-row">
        <app-site-architecture></app-site-architecture>
    </div>
</div>

