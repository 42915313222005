<div class="bio-container">
    <div class="image-container">
    </div>
    <div class="welcome-container">
        <div class="welcome-container-text">
            <h1>Hi, I'm Ashish...</h1>
            <p>
                Currently, I'm a Software Engineer at Meta 
                and I've previously held positions at Bolt Financial, Capital 
                One, and the Federal and Construction sectors. I have {{numberOfYears}} 
                years and {{numberOfMonths}} months of experience in Software 
                Engineering.</p>
            <p>
                Fun fact: I also have 4 years of experience in the 
                Structural Engineering field. I have designed seismically 
                qualified components within numerous nuclear facilities 
                throughout the U.S.
            </p>
            <p>
                Hobbies of mine include real-estate, 
                freelance engineering, software consulting,
                jiu-jitsu (currently a brown belt), and enjoying
                life with my beautiful wife and 2 pups.
            </p>
            <p>
                Check out my site or <a href="https://github.com/asharms26" target="_blank">Github</a> to learn more about me.
            </p>
            <p>
                If you're interested in hiring me, please contact me at <a href="mailto:contact@blupine.co">contact@blupine.co</a> or reach out to me on <a href="https://www.linkedin.com/in/asharma820/">LinkedIn.</a>
            </p>
        </div>
    </div>
</div>
