<div class="portfolio-item" *ngIf="item != undefined">
    <h1 class="portfolio-item-title">
        {{item.title}}
    </h1>
    <div class="portfolio-item-img" style="background-image:url('{{item.img}}');">

    </div>
    <div class="portfolio-item-description">
        <p>{{item.description}}</p>
        <div class="portfolio-item-skills">
            <p *ngFor="let skill of item.skills" class="portfolio-item-skill">{{skill}}</p>
        </div>
    </div>
</div>