<div class="site-architecture-container">
    <h1>This Site</h1>
    <div class="site-architecture-inner-container">
        <p>
            This website is hosted 
            within the Azure Cloud in Azure Storage as a static web application.
            The repository is housed in GitHub, and CI/CD is run
            through Azure DevOps. When I make a change to the code that gets merged,
            this website automatically gets deployed in a matter of seconds. The website is 
            written in Angular, and I haven't used any other third
            party plugins for anything. The styling, animations, canvas elements
            are all custom.
        </p>
        <div class="site-architecture-img site-architecture-azure-img"></div>
        <div class="site-architecture-img site-architecture-angular-img"></div>
    </div>
</div>
