<div class="hobbies-container">
    <div class="hobbies-inner-container">
        <h1>Hobbies and More Fun Facts</h1>
        <p>Being professionally employed in two different industries 
            and having my own LLC to do Software consulting 
            has taught me a deep understanding of business. Being a martial 
            artist has instilled a hard working and disciplined attitude within me.</p>
        <p>Here's some more fun facts about me: </p>
        <ul>
            <li>AirBnB Host</li>
            <li>B.S. Civil Engineering, Virginia Tech (Let's Go Hokies!)</li>
            <li>M.S. Computer Science from Johns Hopkins (Let's go Hop!)</li>
            <li>Competed and medaled in numerous Jiu-Jitsu competitions in the U.S.</li>
            <li>Wannabe bogey golfer...</li>
            <li>Currently living in D.C. proper since 2017</li>
        </ul>
    </div>
    <div class="swiss-image-container"></div>
</div>