import { Component, OnInit } from '@angular/core';

export interface PortfolioItem {
  title: string,
  img: string,
  skills: string[],
  description: string,
}

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  items : PortfolioItem[] = [
    {
      title: "TheaterBlood",
      skills: ["C#","Xamarin","Azure","Azure DevOps"],
      img: "/assets/theaterblood.png",
      description:"A major project for the DHA that managed blood inventory for the military in which we won an award for our work. This was an intricate app that required offline sync on Windows machines that had integration with CAC card scanning. I worked on the Windows application, but my main task was to develop and maintain the DevOps infrastructure. I created approximately 10 different piplines in Azure devops that did everything from building, testing, UI testing with Ranorex, certificate signing, and releasing to multiple different environments. The DevOps pipelines were run on a dedicated Windows host on a VM in Azure. The app had automatic update installation incorporated through the DevOps infrastructure.",
    },
    {
      title: "BlueLinx Plan Log",
      skills: ["C#",".NET","CSLA","Javascript", "JQuery", "File Processing"],
      img: "/assets/bxc.png",
      description: "I developed a brand new Plan Log ingestion application for BlueLinx. This web application required file processing, report generation, a robust form intake with the ability to save form templates for faster ingestion the next time. The backend was in C# and had to comply with the companies existing CSLA 3-tier backend infrastructure."
    },
    {
      title: "Digital Exchange Server",
      skills: ["Angular", "Typescript"],
      img: "/assets/des.png",
      description: "I helped engineer a new Angular application for a SMB Commercial Construction Tech company in the D.C. area."
    },
    {
      title: "TE2 Engineering",
      skills: ["Wordpress", "X Theme"],
      img: "/assets/te2engineering.png",
      description: "I developed a SMB website for an MEP engineering firm in Massachusetts. The business has very close ties with one of the most iconic home improvement shows in U.S. history."
    },
    {
      title: "Alliance Jiu-Jitsu HQ",
      skills: ["AngularJS", "PHP", "WooCommerce", "Lightspeed POS"],
      img: "/assets/alliance.png",
      description: "My first big project when I got my start in Atlanta. I revamped Alliance HQ's website and ecommerce store, and I also integrated a Lightspeed POS inside the gym that had inventory linked on the online store. The year after launch, the gym doubled membership estimated at a net increase of $30K/month in revenue."
    },
    {
      title: "MathSharper",
      skills: ["Xamarin", "Mobile", "iOS", "Android", "C#", ".NET", "IdentityServer", "Azure", "Azure DevOps", "Azure Cosmos", "Azure Redis", "Azure SignalR"],
      img: "/assets/mathsharper.jpg",
      description: "I developed a robust math game with a load of technologies for fun. There was a multiplayer aspect of the game that utilized many different Azure technologies for this game that had a real time multiplayer aspect to it."
    }
  ];

  ngOnInit(): void {
  }

}
